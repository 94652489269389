<template>
  <div class="home">
    <heads></heads>
    <!-- banner -->
    <div class="banner">
      <img src="@/assets/img/banner.png">
    </div>
    <!-- about -->
    <div class="about">
      <div class="all_box">
        <div class="aout_top">
          <div class="about_top_text">
            <div class="about_title_box">
              <h3>邦芒简介</h3>
              <h4>about us</h4>
            </div>
            <div class="about_text-wz">
              <p>
                邦芒人力，创建于2007年，总部位于上海，主营服务外包、人事代理、人力资源SaaS服务、企业福利平台、产业园区运营、招商引资、财税代理、产业投资等多航道业务，并积极探索互联网直聘平台、家居服务一站式平台等创新领域，以业务协作、资源交互的协同增效模式，打造一站式全产业链型企业。  </p>
              <p>
                {{ currentYear }}载耕耘，公司已实现全国核心市场布局，同时陆续在香港和美国等地设立公司，目前全球直营分公司已有228家，服务雇员超100万人。
              </p>
              <p>
                未来，邦芒将继续坚持以技术为驱动，以客户为中心，依托多产业事业集群服务平台、服务全球的骨干网络、行业资深顾问专家库和全域人才数据库，提供全业态、全周期、数字化的“人力资源一站式解决方案”。“大智兴邦，大品传芒”，邦芒人力将持续为客户和雇员创造价值，并积极为推进稳就业、保民生不断努力！
              </p>
            </div>
          </div>
          <div class="about_top_video">
            <!-- <img src="@/assets/img/about_video.jpg"> -->
            <video src="https://1500018560.vod2.myqcloud.com/cba17923vodsh1500018560/fdb4002c243791580028603760/II3yoVTy5J4A.mp4" controls="controls" style="margin-top:20px"></video>
            <!-- <video src="@/assets/video/home_video.mp4" controls="controls" style="margin-top:20px"></video> -->
          </div>
        </div>
        <div class="about_botoom">
          <div class="about_bottom_list">
            <div class="about_bottom_item">
              <h4>深耕行业</h4>
              <div class="big_text">{{ currentYear }}<span>年</span></div>
              <img src="@/assets/img/sanjiao.png" class="sanjiao">
            </div>
            <div class="about_bottom_item">
              <h4>直营公司</h4>
              <div class="big_text">228<span>家</span></div>
              <img src="@/assets/img/sanjiao.png" class="sanjiao">
            </div>
            <div class="about_bottom_item">
              <h4>覆盖城市</h4>
              <div class="big_text">400<span>+</span></div>
              <img src="@/assets/img/sanjiao.png" class="sanjiao">
            </div>
            <div class="about_bottom_item">
              <h4>专业团队</h4>
              <div class="big_text">2600<span>+</span></div>
              <img src="@/assets/img/sanjiao.png" class="sanjiao">
            </div>
            <div class="about_bottom_item">
              <h4>服务客户</h4>
              <div class="big_text">30000<span>+</span></div>
              <img src="@/assets/img/sanjiao.png" class="sanjiao">
            </div>
            <div class="about_bottom_item">
              <h4>雇员数量</h4>
              <div class="big_text">1000000<span>+</span></div>
              <img src="@/assets/img/sanjiao.png" class="sanjiao">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- serve -->
    <div class="serve">
      <div class="all_box">
        <div class="serve_title">
          <h3>邦芒服务</h3>
          <p>OUR SERVICES</p>
          <div class="henxian"></div>
        </div>

        <div class="serve_body">
          <el-tabs v-model="activeName">
            <el-tab-pane label="人事外包HRO" name="first">
              <span slot="label" class="serve_tab">
                <div class="serve_top">
                  <div class="serve_top_picbox"><img src="@/assets/img/serve_off01.png" alt=""></div>
                </div>
                <p>人事服务外包HRO</p>
              </span>
              <div class="serve_box">
                <div class="serve_img">
                  <img src="@/assets/img/serve_pic01.png">
                </div>
                <div class="serve_text">
                  <p>邦芒人事服务外包HRO，根据企业的实际需求，帮助企业简化并整合人力资源管理流程，降低企业人力资源管理成本、优化企业用工风险、实现管理效率最大化。</p>
                  <div class="serve_more" @click="jumpNav('business',1)">
                    <p>查看详情</p>
                    <img src="@/assets/img/home_more_off.png" class="img_off">
                    <img src="@/assets/img/home_more_on.png" class="img_on">
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="业务外包BPO" name="second">
              <span slot="label" class="serve_tab">
                <div class="serve_top">
                  <div class="serve_top_picbox"><img src="@/assets/img/serve_off02.png" alt=""></div>
                </div>
                <p>业务流程外包BPO</p>
              </span>
              <div class="serve_box">
                <div class="serve_img">
                  <img src="@/assets/img/serve_pic02.png">
                </div>
                <div class="serve_text">
                  <p>邦芒业务流程外包BPO, 通过专业化的人员管理模式和规范系统化的操作流程，承接企业分离出的重复性和非核心岗位，帮助企业简化用工程序，降低人员管理成本，提升核心竞争力。</p>
                  <div class="serve_more" @click="jumpNav('business',2)">
                    <p>查看详情</p>
                    <img src="@/assets/img/home_more_off.png" class="img_off">
                    <img src="@/assets/img/home_more_on.png" class="img_on">
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="招聘外包RPO" name="third">
              <span slot="label" class="serve_tab">
                <div class="serve_top">
                  <div class="serve_top_picbox"><img src="@/assets/img/serve_off03.png" alt=""></div>
                </div>
                <p>招聘服务外包RPO</p>
              </span>
              <div class="serve_box">
                <div class="serve_img">
                  <img src="@/assets/img/serve_pic03.png">
                </div>
                <div class="serve_text">
                  <p>邦芒招聘服务外包RPO, 利用自身丰富的行业人才资源、专业的人才评价工具和标准的管理流程优势，帮助企业完成部分或全部员工招聘工作，提升企业招聘质量、优化招聘周期、改善整体行政流程。</p>
                  <div class="serve_more" @click="jumpNav('business',3)">
                    <p>查看详情</p>
                    <img src="@/assets/img/home_more_off.png" class="img_off">
                    <img src="@/assets/img/home_more_on.png" class="img_on">
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="技术服务外包ITO" name="fourth">
              <span slot="label" class="serve_tab">
                <div class="serve_top">
                  <div class="serve_top_picbox"><img src="@/assets/img/serve_off04.png" alt=""></div>
                </div>
                <p>技术服务外包ITO</p>
              </span>
              <div class="serve_box">
                <div class="serve_img">
                  <img src="@/assets/img/serve_pic04.png">
                </div>
                <div class="serve_text">
                  <p>邦芒技术服务外包ITO,
                    推出人事宝科技技术服务，专注于实现科技与人力完美结合，为各行业提供人力资源技术支持服务。<br/>根植于先进的技术条件，人事宝科技始终秉承持续创新与应用实现的服务理念，结合人工智能、云计算、移动互联网等技术，积极为人力资源服务机构搭建数字化创新服务平台，助力人力资源服务在线交付的实现。
                  </p>
                  <div class="serve_more">
                    <p>查看详情</p>
                    <img src="@/assets/img/home_more_off.png" class="img_off">
                    <img src="@/assets/img/home_more_on.png" class="img_on">
                  </div>
                </div>
              </div>
            </el-tab-pane> -->
            <el-tab-pane label="劳务派遣" name="fifth">
              <span slot="label" class="serve_tab">
                <div class="serve_top">
                  <div class="serve_top_picbox"><img src="@/assets/img/serve_off05.png" alt=""></div>
                </div>
                <p>劳务派遣</p>
              </span>
              <div class="serve_box">
                <div class="serve_img">
                  <img src="@/assets/img/serve_pic05.png">
                </div>
                <div class="serve_text">
                  <p>邦芒作为具备劳务派遣经营资质的机构，与劳动者订立劳动合同
                    ，把劳动者派往用工企业，再由用工企业向邦芒支付服务费用的用工形式。邦芒劳务派遣服务能够有效帮助企业减少劳动争议，降低用人风险，自主灵活用工，专注核心业务发展，提升核心竞争力，真正实现
                    "省心"、"省力"、"省钱"。</p>
                  <div class="serve_more" @click="jumpNav('business',4)">
                    <p>查看详情</p>
                    <img src="@/assets/img/home_more_off.png" class="img_off">
                    <img src="@/assets/img/home_more_on.png" class="img_on">
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="短期用工" name="sixth">
              <span slot="label" class="serve_tab">
                <div class="serve_top">
                  <div class="serve_top_picbox"><img src="@/assets/img/serve_off06.png" alt=""></div>
                </div>
                <p>短期用工</p>
              </span>
              <div class="serve_box">
                <div class="serve_img">
                  <img src="@/assets/img/serve_pic06.png">
                </div>
                <div class="serve_text">
                  <p>邦芒为企业的中短期空缺岗位提供招聘、管理、绩效考核、退回人员替换等服务，助力企业实现更合理有效的人力资源配置。</p>
                  <div class="serve_more" @click="jumpNav('business',5)">
                    <p>查看详情</p>
                    <img src="@/assets/img/home_more_off.png" class="img_off">
                    <img src="@/assets/img/home_more_on.png" class="img_on">
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="HR SaaS" name="seventh">
              <span slot="label" class="serve_tab">
                <div class="serve_top">
                  <div class="serve_top_picbox"><img src="@/assets/img/serve_off07.png" alt=""></div>
                </div>
                <p>HR SaaS</p>
              </span>
              <div class="serve_box">
                <div class="serve_img">
                  <img src="@/assets/img/serve_pic07.png">
                </div>
                <div class="serve_text">
                  <p>
                    邦芒提供的技术支持产品包括：HROSaaS系统、OA系统、ERP系统、发薪系统、工资条系统、用工体检系统、个税申报系统等。产品旨在连接企业业务端和个人端，切实提高企业的运营效率，让企业运作更具信息化、数字化、智能化。
                  </p>
                  <div class="serve_more" @click="jumpNav('business',6)">
                    <p>查看详情</p>
                    <img src="@/assets/img/home_more_off.png" class="img_off">
                    <img src="@/assets/img/home_more_on.png" class="img_on">
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>

    <!-- 邦芒优势 -->
    <div class="advantage">
      <div class="all_box">
        <div class="ys_title">
          <h3>邦芒优势</h3>
          <p>{{ currentYear }}年专业人力资源服务提供商</p>
          <img src="@/assets/img/ys_title_img.png">
        </div>
        <div class="ys_list">
          <div class="ys_item">
            <div class="ys_title_sm">优势一</div>
            <div class="ys_img">
              <img src="@/assets/img/ys01.png" class="ys_img_one">
              <img src="@/assets/img/ys_on01.png" class="ys_img_two">
            </div>
            <div class="ys_text">
              全国落地 专属定制
            </div>
            <div class="ys_henxian">

            </div>
          </div>
          <div class="ys_item">
            <div class="ys_title_sm">优势二</div>
            <div class="ys_img">
              <img src="@/assets/img/ys02.png" class="ys_img_one">
              <img src="@/assets/img/ys_on02.png" class="ys_img_two">
            </div>
            <div class="ys_text">
              优质产品 弹性应用
            </div>
            <div class="ys_henxian">

            </div>
          </div>
          <div class="ys_item">
            <div class="ys_title_sm">优势三</div>
            <div class="ys_img">
              <img src="@/assets/img/ys03.png" class="ys_img_one">
              <img src="@/assets/img/ys_on03.png" class="ys_img_two">
            </div>
            <div class="ys_text">
              数智技术 个性支持
            </div>
            <div class="ys_henxian">

            </div>
          </div>
          <div class="ys_item">
            <div class="ys_title_sm">优势四</div>
            <div class="ys_img">
              <img src="@/assets/img/ys04.png" class="ys_img_one">
              <img src="@/assets/img/ys_on04.png" class="ys_img_two">
            </div>
            <div class="ys_text">
              专业团队 保驾护航
            </div>
            <div class="ys_henxian">

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 横幅 -->
    <div class="henfu">
      <h3>邦芒人力布局全球 服务网点已达228家</h3>
    </div>

    <!-- 邦芒人力 -->
    <div class="bmrl_box">
      <div class="all_box">
        <div class="bmrl_title">
          <h3>邦芒人力</h3>
          <p>{{ currentYear }}年专业人力资源服务提供商</p>
          <img src="@/assets/img/ys_title_img.png">
        </div>
        <div class="bmrl_body">
          <el-row :gutter="20">
            <el-col :span="12" id="form_title">
              <div class="body_map">
                <img src="/官网更新图集/邦芒总部大楼.png">
                <div>邦芒总部大楼</div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="body_map">
                <img src="/官网更新图集/邦芒科技大厦.jpg">
                <div>邦芒科技大厦</div>
              </div>
              <!-- <div class="body_swiper">
                <el-carousel height="452px">
                  <el-carousel-item v-for="item in swiperList" :key="item">
                    <div class="bmrl_swiper_img">
                      <img :src="item">
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div> -->
            </el-col>
          </el-row>
        </div>

        <div class="body_form">
          <div class="form_title">
            <img src="@/assets/img/contact_add.png">
            <h3>全国网点查询<span>| 联系邦芒人力</span></h3>
          </div>
          <div class="form_body">
            <el-row>
              <el-col :span="12" style="padding: 10px 10px 10px 0;">
                <el-select v-model="dataForm.sCode" placeholder="省份" filterable  style="width: 100%;" @change="getQList">
                  <el-option v-for="item in options.sList" :key="item.code" :label="item.name" :value="item.code">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="12" style="padding: 10px 0 10px 10px;">
                <el-select v-model="dataForm.qCode" placeholder="城市" filterable  style="width: 100%;">
                  <el-option v-for="item in options.qList" :key="item.code" :label="item.name" :value="item.code">
                  </el-option>
                </el-select>
              </el-col>

              <el-col :span="20" style="padding: 10px 10px 10px 0;">
                <el-input v-model="dataForm.compName" placeholder="请输入内容"></el-input>
              </el-col>

              <el-col :span="4" style="padding: 10px 0 10px 10px;">
                <el-button type="info" style="width: 100%;background: #ab1621;border-radius: 0;border: none;" @click="goToContact">搜索网点</el-button>
              </el-col>

            </el-row>
          </div>
        </div>
      </div>
    </div>




    <!-- 标杆案例 -->

    <div class="bgal">
      <div class="all_box">
        <div class="bgal_title">
          <h3>标杆案例</h3>
          <h4>{{ numberToUpperCase(currentYear) }}年不懈努力</h4>
          <h4>邦芒人力已服务30000+企业</h4>
        </div>
        <div class="bgal_list">
          <div class="bgal_item">
            <div class="bgal_item_img">
              <img src="@/assets/img/anli01.png">
            </div>
            <div class="bgal_item_text">
              邦芒多年来与我们保持着良好的合作关系，他们的服务专业、高效，帮助我们解决了当下的用工难题，我们真挚地祝福邦芒未来会更好！
            </div>
            <div class="bgal_item_foot">
              ——居民服务行业某翘楚企业
            </div>
          </div>
          <div class="bgal_item">
            <div class="bgal_item_img">
              <img src="@/assets/img/anli02.png">
            </div>
            <div class="bgal_item_text">
              邦芒自主研发的ERP和HR SaaS系统，帮助我们实现了人力资源管理数字化转型，提升了我们日常的人事工作效率。未来，希望能与邦芒深入交流合作，成就更好的彼此！
            </div>
            <div class="bgal_item_foot">
              ——能源化工行业某500强企业
            </div>
          </div>
          <div class="bgal_item">
            <div class="bgal_item_img">
              <img src="@/assets/img/anli03.png">
            </div>
            <div class="bgal_item_text">
              在合作过程中，我们能深切地感受到整个团队的热情、专业和活力，无论是在业务辅导还是日常交流中，团队都给予了我们极大的帮助和支持，邦芒的未来会更好！
            </div>
            <div class="bgal_item_foot">
              ——餐饮行业某老牌企业
            </div>
          </div>
          <div class="bgal_item">
            <div class="bgal_item_img">
              <img src="@/assets/img/anli04.png">
            </div>
            <div class="bgal_item_text">
              在与邦芒合作的这几年，在政策解读、人力资源专业服务上给了我们很多的帮助和支持，帮我们解决了百来个社保公积金户的托管问题，感谢你们，希望你们未来越来越好！
            </div>
            <div class="bgal_item_foot">
              ——仓储物流行业某头部企业
            </div>
          </div>
        </div>



        <friend></friend>

      </div>
    </div>

    <!-- 荣誉资质 -->
    <div class="honor">
      <div class="all_box">
        <div class="honor_title">
          <h3>荣誉资质</h3>
          <img src="@/assets/img/new_title_img.png">
        </div>
        <honor></honor>
      </div>
    </div>




    <!-- 新闻中心 -->
    <div class="new_box">
      <div class="all_box">
        <div class="new_title">
          <h3>新闻中心</h3>
          <img src="@/assets/img/new_title_img.png">
        </div>
        <div class="new_hot_box">
          <div class="new_hot_item" @click="gotoNewDetail(hyNewFirst,1)">
            <div class="new_hot_title">
              <p class="new_hot_item_title" v-if="hyNewList.length > 0">{{hyNewList[0].title}}</p>
              <p class="new_hot_item_time" v-if="hyNewList.length > 0">{{hyNewList[0].createDate}}</p>
            </div>
            <img v-if="hyNewList.length > 0" :src="hyNewList[0].imageUrl">
          </div>
          <div class="new_hot_item" @click="gotoNewDetail(bmNewFirst,2)">
            <div class="new_hot_title">
              <p class="new_hot_item_title" v-if="bmNewList.length > 0">{{bmNewList[0].title}}</p>
              <p class="new_hot_item_time" v-if="bmNewList.length > 0">{{bmNewList[0].createDate}}</p>
            </div>
            <img v-if="bmNewList.length > 0" :src="bmNewList[0].imageUrl">
          </div>
        </div>
        <div class="new_list_box">
          <div class="newlist_box_left">
            <div class="new_item newItem" v-for="item in hyNewList" @click="gotoNewDetail(item.id,1)">
              <p class="new_item_text">{{item.title}}</p>
              <p class="new_item_time">{{item.createDate}}</p>
            </div>
          </div>
          <div class="newlist_box_right">
            <div class="new_item newItem" v-for="item in bmNewList" @click="gotoNewDetail(item.id,2)">
              <p class="new_item_text">{{item.title}}</p>
              <p class="new_item_time">{{item.createDate}}</p>
            </div>
          </div>


        </div>
      </div>
    </div>

    <foots></foots>

    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
  </div>
</template>

<script>
  // @ is an alias to /src
  import honor from '@/components/honor/index.vue'
  import heads from '@/components/head/index.vue'
  import foots from '@/components/foot/index.vue'
  import friend from '@/components/friend/index.vue'


  // import netWorkBanner01 from '@/assets/官网更新图集/邦芒科技大厦.jpg'
  // import netWorkBanner02 from '@/assets/img/netWorkBanner02.png'

  export default {
    name: 'Home',
    data() {
      return {
        currentYear: new Date().getFullYear() - 2007,
        hyNewFirst: '',
        bmNewFirst: '',
        activeName: 'first',
        dataForm: {
          sCode: '',
          qCode: '',
          compName: ''
        },
        hyNewList: [],
        bmNewList: [],
        options: {
          sList: [],
          qList: [],
        },
        swiperList: [
          // netWorkBanner01, 
          // netWorkBanner02
        ]
      }
    },
    mounted() {
      //获取省
      this.getSList()
      this.getNewListSc()
      this.getNewListBm()
      //底部路由跳转定位
      if (this.$route.params.type == 1) {
        document.getElementById("form_title").scrollIntoView();
      }

    },
    components: {
      heads,
      foots,
      honor,
      friend
    },
    methods: {
      numberToUpperCase(num) {
        var chineseNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
        var chineseUnit = ["", "十", "百", "千", "万", "亿"];
      
        // 将数字转换为字符串，并将字符串倒序存放
        var numStr = num.toString();
        var numArr = numStr.split("");
      
        // 定义结果字符串
        var result = "";
      
        // 遍历每一位数字并转换为对应的大写中文
        for (var i = 0; i < numArr.length; i++) {
          var digit = parseInt(numArr[i]);
          var unit = numArr.length - i - 1;
      
          if (digit === 0) {
            // 如果当前位数字为 0，则只有单位不为空时才需要添加“零”
            if (unit !== 0) {
              result += chineseNum[digit];
            }
          } else {
            // 当前位数字不为 0，添加大写中文数字和单位
            result += chineseNum[digit] + chineseUnit[unit];
          }
        }
        if (numArr.length == 2 && numArr[0] == 1) {
          result = result.replace(/^[\u4e00-\u9fa5]/g, '');
        }
      
        return result;
      },
      jumpNav(item, type) {

          this.$router.push({
            name: item,
            params: {
              type
            }
          });

      },

      //跳转新闻详情页
      gotoNewDetail(id, type) {
        debugger
        this.$router.push({
          path: '/NewsListBM/Detail/',
          query: {
            id,
            type
          }
        });
      },
      //获取行业动态
      getNewListSc() {
        let data = {
          page: 1,
          limit: 3,
          categoryId: '1612993825084301313',
          orderField:"release_time",
          order:"desc"
        }
        this.$http.get(`/attract_investment/front/show/newsList`, {
          params: {
            ...data
          }
        }).then(({
          data: res
        }) => {
          this.hyNewFirst = res.list[0].id
          this.hyNewList = res.list
        })
      },
      //获取邦芒新闻
      getNewListBm() {
        let data = {
          page: 1,
          limit: 3,
          categoryId: '1612993917329629186',
          orderField:"release_time",
          order:"desc"
        }
        this.$http.get(`/attract_investment/front/show/newsList`, {
          params: {
            ...data
          }
        }).then(({
          data: res
        }) => {
          this.bmNewFirst = res.list[0].id
          this.bmNewList = res.list
        })
      },


      //获取省
      getSList() {
        this.$http.get(`/attract_investment/front/show/getProvinceList`).then(({
          data: res
        }) => {
          this.options.sList = res
        })
      },
      //获取市
      getQList() {
        this.$http.get(`/attract_investment/front/show/getPlacetbPage`, {
          params: {
            parentid: this.dataForm.sCode,
            deep: 2
          }
        }).then(({
          data: res
        }) => {
          this.options.qList = res.list
        })
      },
      //跳转contact
      goToContact() {
        this.$router.push({
          name: 'contact',
          params: {
            dataForm: this.dataForm,
            type: 2
          }
        });
      },
      // 获取新闻
      getNewList() {
        this.$http.get(`/attract_investment/front/show/newsList`)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .about_top_video {
    video {
      width: 596px;
    }
  }

  .bmrl_body {
    margin-top: 30px;
  }

  .form_title {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 10px;

    img {
      width: 32px;
      height: 36px;
      margin-right: 10px;
    }

    h3 {
      color: #ab1621;
      font-size: 24px;
      margin: 0;

      span {
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }

  .body_form {
    background: #ededed;
    box-sizing: border-box;
    padding: 35px 52px;
    margin-top: 80px;
  }

  .honor {
    padding: 75px 0 90px;
  }

  .honor_title h3 {
    font-size: 36px;
    color: #d1b376;
    margin: 0;
    margin-bottom: 15px;
  }

  .honor_body {
    background: #f1f1f1;
    padding: 60px;
    margin-top: 35px;
  }


  .new_box {
    padding: 90px 0;
  }

  .banner {
    width: 100%;
  }

  .banner img {
    width: 100%;
  }

  .about {
    padding: 110px 0 85px;
  }

  .new_hot_item_title {
    width: 60%;
    overflow: hidden;
    height: 25px;
    overflow: hidden;
  }

  .newlist_box_left {
    width: 49%;
  }

  .newlist_box_right {
    width: 49%;
  }

  .newItem {
    width: 100% !important;
  }

  .new_hot_item {
    width: 49%;
  }

  .new_hot_item img {
    width: 100%;
    display: block;
    height: 400px;
  }

  .new_hot_title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .54);
    width: 100%;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
  }

  .new_hot_title p {
    margin: 0;
    font-size: 18px;
    text-align: left;
  }

  .new_item_text {
    text-align: left;
    overflow: hidden;
    width: 60%;
    height: 25px;
  }

  .new_item_time {
    text-align: right;
    overflow: hidden;
  }

  .new_hot_item {
    position: relative;
  }

  .aout_top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .new_list_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 35px 0;
  }

  .about_top_text {
    width: 46%;
  }

  .about_title_box h3 {
    font-size: 36px;
    color: #d4b391;
    margin: 0;
  }

  .about_title_box h4 {
    color: #777777;
    font-size: 18px;
    position: relative;
    text-transform: uppercase;
    margin-top: 10px;
    margin-left: 20px;
  }

  .about_title_box h4::after {
    content: '';
    width: 100px;
    height: 5px;
    background: #d4b391;
    position: absolute;
    left: -3px;
    bottom: -10px;
  }

  .about_title_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .about_text-wz {
    text-align: left;
    font-size: 16px;
    color: #666666;
    line-height: 26px;

  }

  .new_item {
    width: 49%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px dashed #cccccc;
    color: #666666;
  }

  .about_text-wz p {
    margin: 10px 0;
  }

  .about_bottom_item {
    border: 1px dashed #af9575;
    padding: 15px;
    width: 12%;
    position: relative;
  }

  .about_bottom_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .about_bottom_item h4 {
    margin: 0;
    font-size: 16px;
    color: #af9575;
    text-align: left;
  }

  .big_text {
    color: #b21e29;
    font-size: 35px;
    font-weight: bold;
    font-style: oblique;
    margin: 35px 0;
  }

  .big_text span {
    font-size: 14px;
    font-style: initial;
    font-weight: inherit;
  }

  .about_botoom {
    margin-top: 108px;
  }

  .sanjiao {
    position: absolute;
    left: 50%;
    margin-left: -22px;
    bottom: 0;
  }

  .serve_top {
    width: 75px;
    height: 75px;
    border-radius: 75px;
    background: #fff;
    margin: 0 auto;
    line-height: 95px;
  }

  .serve_tab p {
    font-size: 16px;
    color: #656565;
    margin: 0;
  }

  .serve {
    background: #ededed;
    padding-bottom: 105px;
  }

  .serve_title h3 {
    font-size: 36px;
    color: #d4b391;
    margin: 0;
    margin-bottom: 15px;
  }

  .serve_title p {
    font-size: 18px;
    color: #777777;
    margin: 0;
  }

  .serve_title {
    padding: 45px 0;
  }

  .henxian {
    width: 45px;
    height: 5px;
    background: #d4b391;
    margin: 0 auto;
    margin-top: 14px;
  }

  .serve_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 45px;
  }

  .serve_img {
    width: 49%;
    img{
      width: 100%;
    }
  }

  .serve_text {
    width: 49%;
    text-align: left;
    font-size: 16px;
    color: #656565;
    line-height: 36px;
    box-sizing: border-box;
    padding-right: 80px;
  }

  .serve_top_picbox {
    width: 36px;
    height: 36px;
    margin: 0 auto;
    padding-top: 17px;
    position: relative;
    overflow: hidden;
  }

  .serve_top_picbox img {
    width: 100%;
    display: block;
    position: absolute;
    filter: drop-shadow(37px 0 0 #d1ad87);
  }

  .serve_more:hover {
    .img_off {
      z-index: 0;
    }

    .img_on {
      z-index: 1;
    }
  }

  .img_off {
    z-index: 1;
  }

  .img_on {
    z-index: 0;
  }

  .serve_more {
    cursor: pointer;
    position: relative;
    width: 100px;
    height: 35px;
    text-align: center;

    img {
      position: absolute;
      left: 0;
      top: 0;
    }

    p {
      position: absolute;
      z-index: 10;
      margin: 0;
      width: 100%;
      text-align: center;
      font-size: 16px;
      color: #d4b391;
    }
  }

  .serve_more:hover p {
    color: #fff;
  }

  .ys_title h3 {
    font-size: 36px;
    color: #d4b391;
    margin: 0;
  }

  .ys_title p {
    font-size: 16px;
    color: #666666;
  }

  .advantage {
    padding-top: 100px;
    padding-bottom: 135px;
  }

  .ys_henxian {
    width: 20px;
    height: 4px;
    background: #e50011;
  }

  .ys_item {
    background: #ededed;
    width: 24%;
    box-sizing: border-box;
    padding: 35px 25px;
  }

  .ys_item:hover {
    background: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);

    .ys_img_one {
      z-index: 0;
    }

    .ys_img_two {
      z-index: 1;
    }
  }

  .ys_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 65px;
  }

  .ys_title_sm {
    font-size: 16px;
    color: #c2a180;
    text-align: left;
  }

  .ys_img {
    margin-top: 6px;
    margin-bottom: 20px;
    position: relative;
    width: 238px;
    height: 90px;

    img {
      position: absolute;
      left: 75px;
    }

    .ys_img_one {
      z-index: 1;
    }

    .ys_img_two {
      z-index: 0;
    }
  }


  .ys_text {
    font-size: 16px;
    color: #454545;
  }

  .ys_henxian {
    margin: 0 auto;
    margin-top: 16px;
  }

  .bmrl_title h3 {
    font-size: 36px;
    color: #d4b391;
  }

  .bmrl_title p {
    font-size: 16px;
    color: #666666;
  }

  .henfu {
    height: 231px;
    background: url("../../src/assets/img/henfu_bg.png") center center no-repeat;
    font-size: 60px;
    color: #fff;

  }

  .henfu h3 {
    font-weight: 400;
    margin: 0;
    padding: 65px 0;
  }

  .bmrl_box {
    padding: 75px 0;
  }

  .bmrl_title h3 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 36px;
  }

  .bmrl_title p {
    margin: 0;
    margin-bottom: 10px;
    font-size: 16px;
  }

  .bgal {
    background: url(../../src/assets/img/anli_bg.png) center center no-repeat;
    padding: 75px 0 125px;
    background-size: 100% 100%;
  }

  .bgal_title h3 {
    font-size: 36px;
    color: #666666;
    margin: 0;
    margin-bottom: 20px;
  }

  .bgal_title h4 {
    font-size: 36px;
    color: #333333;
    margin: 0;
    margin-bottom: 20px;
  }

  .bgal_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;
    margin-bottom: 50px;
  }

  .bgal_item {
    width: 24%;
    background: #fff;
    padding-bottom: 35px;
  }

  .bgal_item_text {
    text-align: left;
  }

  .bgal_item_text {
    padding: 30px 18px;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    height: 130px;
  }

  .bgal_item_foot {
    text-align: right;
    font-size: 14px;
    color: #b82323;
    padding: 0 18px;
  }

  .bgal_item_img img {
    width: 100%;
  }



  .new_title h3 {
    font-size: 35px;
    color: #d4b391;
    margin: 0;
    margin-bottom: 28px;
  }

  .new_hot_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
  }
</style>
<style lang="scss">
  .logo_box {
    .el-tabs__nav-wrap::after {
      display: none;
    }

    .el-tabs__nav {
      margin: 0 auto;
      text-align: center;
      clear: both;
      float: initial;
    }

    .el-tabs__active-bar {
      display: none;
    }

    .el-tabs__item {
      font-size: 14px;
      color: #666666;
    }

    .el-tabs__item.is-active {
      color: #cc9966;
    }

    .el-tabs__item:hover {
      color: #cc9966;
    }
  }


  .serve_body {
    .el-tabs__item {
      height: auto !important;
      width: 170px;
    }

    .el-tabs__item:nth-child(2) {
      padding-left: 20px !important;
    }

    .el-tabs__item:last-child {
      padding-right: 20px !important;
    }

    .el-tabs__item:nth-child(6),
    .el-tabs__item:nth-child(7),
    .el-tabs__item:nth-child(8) {
      width: 160px;
    }

    .el-tabs__nav {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .el-tabs__active-bar {
      display: none;
    }

    .el-tabs__nav-wrap::after {
      background-color: #c3c3c3 ! important;
    }

    .el-tabs__nav-scroll {
      padding-bottom: 13px;
    }

    .is-active {
      p {
        color: #d1ad87;
      }

      .serve_top_picbox img {
        left: -37px;
      }
    }
  }

  .body_map > img {
    width: 100%;
  }
</style>