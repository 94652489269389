<template>

    <div class="honor_body">
        <!-- <vue-seamless-scroll :data="listData" :class-option="classOption" class="warp">
            <ul class="ul-item">
                <li class="li-item" v-for="(item, index) in listData" :key="index">
              {{item}}
                    <img :src="item">
                </li>
            </ul>
        </vue-seamless-scroll> -->



        <div class="ceshi">

            <hooper :infiniteScroll="true" :itemsToShow="5" :autoPlay="true" :playSpeed="3000">
                <slide v-for="(item,index) in listData" :key="index">
                    <div class="honor_item_box">
                        <img :src="item">
                    </div>
                </slide>

                <hooper-navigation slot="hooper-addons"></hooper-navigation>
            </hooper>

        </div>
    </div>

</template>
<script>
    import {
        Hooper,
        Slide,
        Navigation as HooperNavigation
    } from 'hooper';
    import 'hooper/dist/hooper.css';
    // important honor
    import honor01 from '@/assets/honor/honor01.jpg'
    import honor02 from '@/assets/honor/honor02.jpg'
    import honor03 from '@/assets/honor/honor03.jpg'
    import honor04 from '@/assets/honor/honor04.jpg'
    import honor05 from '@/assets/honor/honor05.jpg'
    import honor06 from '@/assets/honor/honor06.jpg'
    import honor07 from '@/assets/honor/honor07.jpg'
    import honor08 from '@/assets/honor/honor08.jpg'
    import honor09 from '@/assets/honor/honor09.jpg'
    import honor10 from '@/assets/honor/honor10.jpg'
    import honor11 from '@/assets/honor/honor11.jpg'
    import honor12 from '@/assets/honor/honor12.jpg'
    import honor13 from '@/assets/honor/honor13.jpg'
    import honor14 from '@/assets/honor/honor14.jpg'
    import honor15 from '@/assets/honor/honor15.jpg'
    import honor16 from '@/assets/honor/honor16.jpg'
    import honor17 from '@/assets/honor/honor17.jpg'
    import honor18 from '@/assets/honor/honor18.jpg'
    import honor19 from '@/assets/honor/honor19.jpg'
    import honor20 from '@/assets/honor/honor20.jpg'
    import honor21 from '@/assets/honor/honor21.jpg'
    import honor22 from '@/assets/honor/honor22.jpg'
    import honor23 from '@/assets/honor/honor23.jpg'
    import honor24 from '@/assets/honor/honor24.jpg'
    import honor25 from '@/assets/honor/honor25.jpg'
    import honor26 from '@/assets/honor/honor26.jpg'
    import honor27 from '@/assets/honor/honor27.jpg'
    import honor28 from '@/assets/honor/honor28.jpg'
    import honor29 from '@/assets/honor/honor29.jpg'
    import honor30 from '@/assets/honor/honor30.jpg'
    import honor31 from '@/assets/honor/honor31.jpg'
    import honor32 from '@/assets/honor/honor32.jpg'
    export default {
        data() {
            return {

                // classOption: {
                //     limitMoveNum: 2,
                //     direction: 3,
                // },
                listData: [honor01, honor02, honor04, honor05, honor06, honor03, honor07, honor08, honor09, honor10,
                    honor11,
                    honor12, honor13, honor14, honor15, honor16, honor17, honor18, honor19, honor20, honor21,
                    honor22, honor23,
                    honor24, honor25, honor26, honor27, honor28, honor29, honor30, honor31,honor32
                ]
            }
        },
        mounted() {
            document.getElementsByClassName(`hooper-prev`)[0].innerHTML = ''
            document.getElementsByClassName(`hooper-next`)[0].innerHTML = ''
        },
        components: {
            Hooper,
            Slide,
            HooperNavigation
        }
    }
</script>
<style lang="scss" scoped>
    .honor_item_box {
        width: 190px;
        height: 138px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 5px;
        background: url(../../assets/img/honorbg.png) center center no-repeat;
        background-size: 100%;

        img {
            width: 100%;
        }
    }

    .ceshi {
        .hooper {
            height: 150px;
        }

    }
</style>
<style lang="scss">
    .ceshi {
        .hooper-prev {
            background: url(../../assets/img/honor_left.png) center center no-repeat;
            left:-40px;
            width:30px;
            height:42px;
            background-size: 100% 100%;
        }
        
        .hooper-next {
            background: url(../../assets/img/honor_right.png) center center no-repeat;
            right:-40px;
            width:30px;
            height:42px;
            background-size: 100% 100%;
        }
    }
</style>